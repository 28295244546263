
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "edit-building-house-insurance-claim-component",
  components: { Form, Field, ErrorMessage, ImagesUploader },
  props: ["publicId"],
  data() {
    return {
      icClass: "col-xl-12",
      hprClass: "col-xl-6",
      fbClass: "col-xl-6",
      dtClass: "col-xl-6",
      afaClass: "col-xl-6",
      natureClass: "col-xl-12",
      hasFireBrigadeReport: false,
      hasPoliceReport: false,
      claimNature: "",
      itemPublicId: "",
      lossDate: "",
      todaysDate: "",
      policeReport: "",
      amount: 0.0 as number,
      policy: "",
      affectedAreaPicture: "",
      fireBrigadeReport: "",
      affectedAreaImages: "",
      descriptionOfIncident: "",
      acceptTerms: false,
      affectedItems: [
        {
          itemName: "",
          amount: "",
          receipt: ""
        }
      ],
      policies: [],
      buildings: [],
      role: "",
      route: "",
      policyItemRoute: "",
      policyStartDate: ""
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed().required().label("Policy reference"),
      itemPublicId: Yup.mixed().required().label("Building"),
      claimNature: Yup.mixed().required().label("Claim nature"),
      lossDate: Yup.date().required().label("Date and time of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident"),
      acceptTerms: Yup.boolean()
        .oneOf([true])
        .required()
        .label("Terms & conditions")
    });

    const submitForm = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);

            ApiService.setHeader();
            ApiService.post(
              `${variables.CLAIMS_ROUTE}/${props.publicId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "client-claims-history"
                });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Building", [
        "Claims",
        "Report Claims",
        "House Insurance Claim"
      ]);
    });

    return { submitButton1, claimsValidator, submitForm };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    this.role = store.getters.currentUser.role;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get claim information route based on user roles
    if (this.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.POLICIES_ROUTE}`;
    } else if (this.role == variables.BROKER_USER_ROLE) {
      // Broker role claim information route
      // this.route = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}/${this.publicId}`;
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE}`;
    } else if (
      this.role == variables.ADMIN_USER_ROLE ||
      this.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role claim information route
      this.route = `${variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}`;
    }

    // Create local storage item for affected amounts
    // localStorage.setItem("affectedTotalAmount", "0");

    // Get the current date
    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    // Get the associated claim information
    this.getClaimInformation();

    //Set page title
    document.title =
      "Edit Building House Insurance Claim | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.claimNature = data.data.metadata.claimNature;
            this.policy = data.data.metadata.policy;
            this.getPolicyInformation(data.data.metadata.policy);
            this.itemPublicId = data.data.metadata.itemPublicId;
            this.lossDate = data.data.metadata.lossDate;
            this.amount = data.data.metadata.amount;
            this.descriptionOfIncident =
              data.data.metadata.descriptionOfIncident;
            this.policyStartDate = data.data.policyStartDate;
            this.affectedItems = data.data.metadata.affectedItems;

            if (data.data.lossType == "Fire") {
              this.hasFireBrigadeReport = true;
              this.dtClass = "col-xl-4";
              this.afaClass = "col-xl-4";
              this.natureClass = "col-xl-6";
            } else if (data.data.lossType == "Theft") {
              this.hasPoliceReport = true;
              this.dtClass = "col-xl-4";
              this.afaClass = "col-xl-4";
              this.natureClass = "col-xl-6";
            } else {
              this.defaultValues();
            }
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            console.log(error);
            router.go(-1);
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/package/HOU`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.policyItemRoute}/${value}/items`)
          .then(({ data }) => {
            //Assign data to variables
            this.buildings = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getclaimNature(event) {
      this.defaultValues();

      const value = event.target.options[event.target.selectedIndex].value;

      // If the claim nature is accident
      if (value == "Fire") {
        this.hasFireBrigadeReport = true;
        this.dtClass = "col-xl-4";
        this.afaClass = "col-xl-4";
        this.natureClass = "col-xl-6";
      } else if (value == "Theft") {
        this.hasPoliceReport = true;
        this.dtClass = "col-xl-4";
        this.afaClass = "col-xl-4";
        this.natureClass = "col-xl-6";
      } else {
        this.defaultValues();
      }
    },
    estimatedRepairsCalculation(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Append total amount of affected areas amount to the amount field
        this.amount = variables.totalEstimateOfRepairs();
      } else {
        return;
      }
    },
    getCurrentDate() {
      this.todaysDate = variables.currentDate();
    },
    defaultValues() {
      this.icClass = "col-xl-12";
      this.hprClass = "col-xl-6";
      this.fbClass = "col-xl-6";
      this.dtClass = "col-xl-6";
      this.afaClass = "col-xl-6";
      this.natureClass = "col-xl-12";
      this.hasFireBrigadeReport = false;
      this.hasPoliceReport = false;
    },
    addDynamicField() {
      this.affectedItems.push({
        itemName: "",
        amount: "",
        receipt: ""
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      this.affectedItems.splice(counter, 1);
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(amount) {
      return variables.addCommaToNumber(amount);
    }
  }
});
